// consumer_web_company_hzSupplementCompanyInfo
const __request = require(`./__request/__request_contentType_json`)
const consumer_web_company_hzSupplementCompanyInfo = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/company/hzSupplementCompanyInfo',
        data: data
    }
    return __request(pParameter)
}
/**获取发票列表 */

export default consumer_web_company_hzSupplementCompanyInfo