import consumer_web_company_hzSupplementCompanyInfo from '@/lib/data-service/haolv-default/consumer_web_company_hzSupplementCompanyInfo'
import consumer_web_company_getHZIndustryType from '@/lib/data-service/haolv-default/consumer_web_company_getHZIndustryType'
import EmunSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/emun-selector/1.0.0/index.vue';
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
export default {
    data() {
        const validateCompanyFund = (rule, value, callback) => {
            if (value < 0) {
                callback(new Error('注册基金必须大于0'))
            } else {
                callback()
            }
        }
        const validateMarketValue = (rule, value, callback) => {
            if (value < 0) {
                callback(new Error('市值必须大于0'))
            } else {
                callback()
            }
        }
        const validateYearEndTurnover = (rule, value, callback) => {
            if (value < 0) {
                callback(new Error('年底营业额必须大于0'))
            } else {
                callback()
            }
        }
        return {
            loading: false,
            form: {
                companyFund: 0,
                companyIndustry: '',
                hasChinaTopFiveHundred: false,
                hasCountryOrCauseUnit: false,
                hasCountryOwner: false,
                hasWorldTopTwoThousand: false,
                marketValue: 0,
                monthAvgTravelBudget: 0,
                oneMonthTravelNumber: 0,
                yearEndTurnover: 0,
            },
            rules: {
                companyFund: [
                    { required: true, message: '注册资金不能为空'},
                    { type: 'number', message: '注册资金必须为数字值'},
                    { validator: validateCompanyFund, trigger: 'blur' }
                ],
                marketValue: [
                    { required: true, message: '市值不能为空'},
                    { type: 'number', message: '市值必须为数字值'},
                    { validator: validateMarketValue, trigger: 'blur' }
                ],
                yearEndTurnover: [
                    { required: true, message: '年底营业额不能为空'},
                    { type: 'number', message: '年底营业额必须为数字值'},
                    { validator: validateYearEndTurnover, trigger: 'blur' }
                ],
                monthAvgTravelBudget: [
                    { type: 'number', message: '月均差旅预算必须为数字值'},
                ],
                oneMonthTravelNumber: [
                    { type: 'number', message: '单月差旅出行人次必须为数字值'},
                ],
                hasChinaTopFiveHundred: [
                    { type: 'boolean', required: true, message: '请勾选是否中国500强企业', trigger: 'change' }
                ],
                hasWorldTopTwoThousand: [
                    { type: 'boolean', required: true, message: '请勾选是否全球2000强企业', trigger: 'change' }
                ],
                hasCountryOrCauseUnit: [
                    { type: 'boolean', required: true, message: '请勾选是否国家单位/国家事业单位', trigger: 'change' }
                ],
                hasCountryOwner: [
                    { type: 'boolean', required: true, message: '请勾选是否市级及以上国有控股企业/实际利益人企业', trigger: 'change' }
                ]
            }
        }
    },
    components: {EmunSelector},
    created() {
    },
    mounted() {
        this.$refs.companyIndustryEmunSelector.init({
            type: `100`,
            request_url: null,
            entity_define: {
                property_name_value: 'value',
                property_name_text: 'text',
            },
            get_data_source() {
                const data = {
                    entity_list: [],
                };
                return consumer_web_company_getHZIndustryType().then((res) => {
                    const list = res.datas.resultList;
                    data.entity_list = list
                    return data;
                })
            },
            option_all: {
                is_show: false,
            },
        })
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        resetForm() {
            this.form = {
                companyFund: 0,
                companyIndustry: '',
                hasChinaTopFiveHundred: false,
                hasCountryOrCauseUnit: false,
                hasCountryOwner: false,
                hasWorldTopTwoThousand: false,
                marketValue: 0,
                monthAvgTravelBudget: 0,
                oneMonthTravelNumber: 0,
                yearEndTurnover: 0,
            }
        },
        back() {
            this.$router.go(-1)
        },
        async submit() {
            let checkRes = await this.checkForm()
            console.log(checkRes)
            if (!checkRes) {
                return
            }
            this.loading = true
            let form = JSON.parse(JSON.stringify(this.form))
            form.companyFund = form.companyFund.toString()
            form.marketValue = form.marketValue.toString()
            form.yearEndTurnover = form.yearEndTurnover.toString()
            form.monthAvgTravelBudget = form.monthAvgTravelBudget.toString()
            form.oneMonthTravelNumber = form.oneMonthTravelNumber.toString()
            let [err, res] = await awaitWrap(consumer_web_company_hzSupplementCompanyInfo(form))
            if (err) {
                this.loading = false
                return
            }
            this.loading = false
            if (res.result.code === 200) {
                // 提示3秒，返回首页
                this.$message({
                    message: '提交成功，审核结果将于2个工作日内线下告知，请耐心等待！',
                    type: 'success'
                });
                setTimeout(() => {
                    this.back()
                }, 3000)
            } else {
                this.$message({
                    type: "warning",
                    message: res.result.message
                })
            }
        },
        checkForm() {
            return new Promise((resolve, reject) => {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
            })
        },
    }
}